.footer-div {
    /* Container */
    padding: 50px;
    background-color: #303030;
    height: 362px;
    color: #ffffff;

    /* font */
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
}

.footer-div a {
    color: #ffffff;
}

.com-name {
    font-weight: 700;
}

.footer-inc {
    margin-top: 130px;
    font-size: 14px;
    line-height: 17px;
}