.about-div {
    padding: 3rem;
    text-align: center;
    background: linear-gradient(rgba(255,255,255,.7), rgba(255,255,255,.7)), url("../../images/about-bg.jpg");
    min-height: 986px;
}

.about-div p {
    max-width: 1024px;
    font-weight: 300;
    font-size: 32px;
    color: black;
    margin: 0 auto;
}

.about-div h3 {
    font-weight: 700;
    font-size: 40px;
    color: black;
    margin-top: 70px;
    text-shadow: 4px 4px 8px rgb(0, 0, 0, 0.25);
}

.about-story-title {
    color: #808080;
    font-weight: 700;
    font-size: 40px;
    text-shadow: 4px 4px 8px rgb(0, 0, 0, 0.25);
}

.about-com-title {
    font-weight: 700;
    font-size: 64px;
    color: black !important;
    text-shadow: 5px 5px 10px rgb(0, 0, 0, 0.25);
}

@media screen and (max-width: 580px) {
    .about-story-title {
        font-size: 32px;
    }

    .about-com-title {
        font-size: 40px;
    }
}