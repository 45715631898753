.banner-div {
    padding: 3rem;
    text-align: center;
    background: linear-gradient(rgba(255,255,255,.7), rgba(255,255,255,.7)), url("../../images/banner-bg.jpg");
    min-height: 986px;
}

.banner-content {
    margin: 24% 0 0 50%;
}

.banner-div p {
    max-width: 1024px;
    font-weight: 300;
    font-size: 32px;
    color: black;
    margin: 96px auto 37px auto;
}

.btn-touch {
    min-width: 300px;
    height: 91px;
    border: 3px solid #000000;
    border-radius: 25px;
    font-size: 32px;
    font-weight: 700;
}

.banner-div h2 {
    color: #808080;
    font-weight: 700;
    font-size: 40px;
    text-shadow: 4px 4px 8px rgb(0, 0, 0, 0.25);
}

.banner-div h1 {
    font-weight: 700;
    font-size: 64px;
    color: black;
    text-shadow: 4px 4px 8px rgb(0, 0, 0, 0.25);
}

@media screen and (max-width: 1024px) {
    .banner-content {
        margin: 25% auto;
    }
}

@media screen and (max-width: 420px) {
    .banner-div h1 {
        font-size: 48px;
    }

    .banner-div h2 {
        font-size: 32px;
    }
}