.scroll-up-btn {
    position: relative;
    top: 10px;
    background-color: #D9D9D9 !important;
    width: 65px;
    height: 65px;
    border: none;
    border-radius: 100%;
    color: black !important;
}
