.contact-div {
    padding: 3rem 0;
    text-align: center;
    background: linear-gradient(rgba(255,255,255,.7), rgba(255,255,255,.7)), url("../../images/contact-bg.jpg");
    min-height: 1550px;
}

.contact-div h1 {
    font-size: 64px;
    font-weight: 700;
    margin-top: 79px;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
}

.contact-div p {
    max-width: 1024px;
    font-weight: 300;
    font-size: 32px;
    color: black;
    margin: 0 auto;
}

.contact-form {
    max-width: 827px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 700;
}

.contact-form .form-control {
    border: 1px solid #000000;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.submit-btn {
    width: 397px;
    height: 58px;
    border: 1px solid #000000;
    background-color: #343434;
    border-radius: 20px;
    color: white;
    font-size: 24px;
    font-weight: 700;
}

.responsive-iframe {
    width: 90vw;
    height: 1096px;
    border: none;
}

@media screen and (max-width: 420px) {
    .contact-div h1 {
        font-size: 48px;
    }

    .contact-div p {
        font-size: 28px;
    }
}