.services-div {
    padding: 3rem;
    text-align: center;
    min-height: 1024px;
}

.services-div h1 {
    font-size: 64px;
    font-weight: 700;
    text-shadow: 5px 5px 10px rgb(0, 0, 0, 0.25);
}

.services-div h3 {
    font-size: 32px;
    font-weight: 700;
    text-shadow: 4px 4px 8px rgb(0, 0, 0, 0.25);
}

.services-title {
    color: #808080;
    font-weight: 700;
    font-size: 40px;
    text-shadow: 4px 4px 8px rgb(0, 0, 0, 0.25);
}

.service-div {
    margin: 20px 0;
    padding: 3rem;
    border: 1px solid #C8C8C8;
    border-radius: 25px;
    min-height: 348px;
}

.service-div li {
    font-size: 24px;
    font-weight: 300;
    text-align: left;
}

@media screen and (max-width: 420px) {
    .services-div h1 {
        font-size: 44px;
    }

    .services-title {
        font-size: 32px;
    }
}